
const surveyListDataStore = {
  state : {
    surveyDataList : {itemList : [], delSiids : []},
    fileDataList : new FormData(),
    fileSeq : 0,
    questionList : [],
    answerList : [],
    questionSeq : 1,
  },
  getters : {
    getSurveyDataList(state) {
      return state.surveyDataList
    },
    getFileDataList(state) {
      return state.fileDataList
    },
    getQuestionList(state) {
      return state.questionList
    },
    getQuestionSeq(state) {
      return state.questionSeq
    },
    getAnswerList(state) {
      return state.answerList
    },
  },
  mutations: {
    setAnswerList(state, param){
      state.answerList = param
    },
    setQuestionList(state, param){
      state.questionList = param
    },
    setQuestionSeq(state, param){
      state.questionSeq = param
    },
    setSurveyDataList(state, param){
      state.surveyDataList = param
      state.fileDataList = new FormData()
      state.fileSeq = 0
      state.surveyDataList.delSiids = new Array()
    },
    addFormData(state, params) {
      let file = params.selectedFile
      let orderNum = params.orderNum
      let formDataKey = state.surveyDataList.itemList[orderNum].formDataKey
      console.log(formDataKey)
      let keyName = formDataKey != "" && formDataKey != null ? formDataKey : 'file' + state.fileSeq++

      state.fileDataList.set(keyName, file)

      state.surveyDataList.itemList[orderNum].formDataKey = keyName

      for (let key of state.fileDataList.keys()) {
        console.log(key, ":", state.fileDataList.get(key));
      }
    },
    addCard(state, param){
      let orderNum = state.surveyDataList.itemList.length

      if(param == "question"){
        state.surveyDataList.itemList.push({
          "title": "",
          "description": "",
          "surveyType": 3,
          "itemType": 3,
          "optYn": "Y",
          "optionList": [
            {
              "orderNum": 0,
              "optType": 0,
              "cont": ""
            },
            {
              "orderNum": 1,
              "optType": 0,
              "cont": ""
            }
          ],
          "orderNum": orderNum
        })
      }else if (param == "title"){
        state.surveyDataList.itemList.push({
          "title": "",
          "description": "",
          "surveyType": "0",
          "itemType": "0",
          "orderNum": orderNum,
          "optYn": "N",
          "requireYn": "N",
          "optionList": []
        })
      }else if (param == "image"){
        state.surveyDataList.itemList.push({
          "title": "",
          "description": "",
          "surveyType": "1",
          "itemType": "1",
          "orderNum": orderNum,
          "optYn": "N",
          "requireYn": "N",
          "optionList": [],
          "fileName": "",
          "formDataKey": ""
        })
      }else if (param == "video"){
        state.surveyDataList.itemList.push({
          "title": "",
          "description": "",
          "surveyType": "2",
          "itemType": "2",
          "orderNum": orderNum,
          "optYn": "N",
          "requireYn": "N",
          "optionList": [],
          "fileName": "",
          "formDataKey": ""
        })
      }

    },
    deleteCard (state, params) {

      let orderNum = params.orderNum
      let siid = params.siid

      state.surveyDataList.itemList.splice(orderNum, 1);

      for (let i = 0; i < state.surveyDataList.itemList.length; i++) {
        state.surveyDataList.itemList[i].orderNum = i;
      }

      if(siid != null){
        state.surveyDataList.delSiids.push(siid)
      }
    },
    deleteOption (state, params) {

      let cardNum = params.cardOrderNum
      let optNum = params.optionOrderNum

      state.surveyDataList.itemList[cardNum].optionList.splice(optNum, 1);

      for (let i = 0; i < state.surveyDataList.itemList[cardNum].optionList.length; i++) {
        state.surveyDataList.itemList[cardNum].optionList[i].orderNum = i;
      }
    },
    deleteFormData (state, key) {

      // 필드 삭제
      state.fileDataList.delete(key); // key로 필드 삭제
    },
    changeOrderUp(state, orderNum){

      if(orderNum != 0){

        let arr = state.surveyDataList.itemList

        arr[orderNum].orderNum = orderNum - 1
        arr[orderNum - 1].orderNum = orderNum

        const tmp = arr[orderNum];
        arr[orderNum] = arr[orderNum - 1];
        arr[orderNum - 1] = tmp;
      }
    },
    changeOrderDown (state, orderNum) {

      if (orderNum != state.surveyDataList.itemList.length-1) {

        let arr = state.surveyDataList.itemList

        arr[orderNum].orderNum = orderNum + 1
        arr[orderNum + 1].orderNum = orderNum

        const tmp = arr[orderNum + 1]
        arr[orderNum + 1] = arr[orderNum]
        arr[orderNum] = tmp
      }
    },
    addOption (state, params) {

      let cardOrderNum = params.orderNum
      let cardInfo = params.cardInfo

      let option = {
        "orderNum": cardInfo.optionList.length,
        "cont": "",
        "optType": 0
      }

      console.log(option)

      state.surveyDataList.itemList[cardOrderNum].optionList.push(option);
    },
    addEtc (state, params) {

      let cardOrderNum = params.orderNum
      let cardInfo = params.cardInfo

      let option = {
        "orderNum": cardInfo.optionList.length,
        "cont": "",
        "optType": 1
      }

      console.log(option)

      state.surveyDataList.itemList[cardOrderNum].optionList.push(option);
    },
    requireYn (state, params) {

      let orderNum = params.orderNum
      let requireValue = params.requireValue

      state.surveyDataList.itemList[orderNum].requireYn = requireValue === "Y" ? "N" : "Y"
    },
    moveFlowMenu () {

      let surveyCards = document.getElementsByClassName("survey_card");
      let lastSurveyCard = surveyCards[surveyCards.length - 1];

      let surveyFormWrap = document.getElementsByClassName("survey_form_wrap")[0];

      var targetCardTop = lastSurveyCard.getBoundingClientRect().top;
      var surveyFormWrapTop = surveyFormWrap.getBoundingClientRect().top;

      var position = targetCardTop - surveyFormWrapTop;

      document.querySelector('.flow_menu').style.top = position + 'px' ;
    }
  },
  actions: {

  }
}
export default surveyListDataStore
