<!--
  컨텐츠 영역 포함된 전체 레이아웃..
-->
<script setup>
import CommonHeader from '@/components/common/CommonHeader.vue';
import { onMounted } from 'vue';

onMounted (() => {
  console.log("layoutContentsMain.vue ==============================================")
})

</script>
<template>

    <Common-header></Common-header>
    <router-view></router-view>

</template>
<style scoped>

</style>
