/**
 * api 공통
 */
import NetworkService from "@/api/net.service";

const NetApiServer = new NetworkService("")

/**
 *  리포트 api
 * @type {{getSidoList(): Promise<AxiosResponse<any>>}}
 */
export const ApiReport = {

  /**
   * KOQUSS-40 CSV 출력
   * @returns {Promise<AxiosResponse<any>>}
   */
  getKoquss40Report(params){
    return NetApiServer.downloadCsv("/api/report/koquss40Report", params).then(res => {
      return res
    })
  },

  /**
   * KOQUSS-33 CSV 출력
   * @returns {Promise<AxiosResponse<any>>}
   */
  getKoquss33Report(params){
    return NetApiServer.downloadCsv("/api/report/koquss33Report", params).then(res => {
      return res
    })
  },

  /**
   * 식이 및 NRS2002+환자증상 CSV 출력
   * @returns {Promise<AxiosResponse<any>>}
   */
  getNRS2002Report(params){
    return NetApiServer.downloadCsv("/api/report/nrs2002Report", params).then(res => {
      return res
    })
  },

  /**
   * EORTC QLQ-STO22 CSV 출력
   * @returns {Promise<AxiosResponse<any>>}
   */
  getSTO22Report(params){
    return NetApiServer.downloadCsv("/api/report/sto22Report", params).then(res => {
      return res
    })
  },

  /**
   * C30 CSV 출력
   * @returns {Promise<AxiosResponse<any>>}
   */
  getC30Report(params){
    return NetApiServer.downloadCsv("/api/report/c30Report", params).then(res => {
      return res
    })
  },
}
