import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router";
import store from "@/store/store";
import CKEditor from '@ckeditor/ckeditor5-vue';
import VueDayjs from 'vue3-dayjs-plugin'
import { createMetaManager } from 'vue-meta'
import createPersistedState from "vuex-persistedstate"
import VueDatePicker from '@vuepic/vue-datepicker';
import '@/assets/css/vue-datepicker.css'

// 공통 함수들
import { Common } from '@/utils/common'

const app = createApp(App)

app.component('VueDatePicker', VueDatePicker);
app.use(router)
app.use(store)
app.use(Common)
app.use(createPersistedState)
app.use(CKEditor)
app.use(VueDayjs)
app.use(createMetaManager())

app.config.productionTip = false
app.config.errorHandler = (err, vm, info) => {
  // 에러 처리
  // `info` 는 Vue 관련 에러 정보 입니다. 예) 라이프사이클 훅에 에러를 발견
  console.error(err)
  console.error(vm)
  console.error(info)
}
// app 전역 설정 변수
app.config.globalProperties.version = '1.0.0.'

app.mount("#app")
