<!--
  Full 페이지 레이아웃
-->
<script setup>

</script>
<template>
  <router-view></router-view>
</template>
<style scoped>

</style>
