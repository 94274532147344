

const prevUrlStore = {
    state : {
        prevUrl : "" // 이전 url
    },
    getters : {
        getPrevUrl(state) {
            return state.prevUrl
        }
    },
    mutations: {
        setPrevUrl(state, param){
            state.prevUrl = param
        }
    },
    actions: {

    }
}
export default prevUrlStore