
const newTabParamsStore = {
  state : {
    params : {
      'patiSeq' : '',
      'surveyCycleList' : []
    },
    params2 : {
    }
  },
  getters : {
    getParams(state) {
      return state.params
    },
    getParams2(state) {
      return state.params2
    }
  },
  mutations: {
    setParams(state, param){
      state.params = param
    },
    setParams2(state, param){
      state.params2 = param
    }
  },
  actions: {

  }
}
export default newTabParamsStore
