<script setup>

import '@/assets/css/common.css'
import '@/assets/css/survey.css'
import '@/assets/css/jquery-ui.min.css'
import '@/assets/css/noto-sans-kr.css'


import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import LayoutContentsMain from "@/views/layout/layoutContentsMain.vue";
import LayoutContents from "@/views/layout/layoutContents.vue";
import LayoutFull from "@/views/layout/layoutFull.vue";


const router = useRouter()
const route = useRoute()
// router 에서 설정된 레이아웃 조회
const layout = computed(() => {

  let layout = router.currentRoute.value.meta.layout
  if(layout === undefined){
    layout = "layoutContents"
  }
  console.log("-=-------------------------");
  console.log("layout: %s", router.currentRoute.value.meta.layout);
  console.log("bodyClass: %s", router.currentRoute.value.meta.bodyclass);



  // 최대 확대 비율 설정 함수

  const metaTag = document.querySelector('meta[name="viewport"]');

  if (metaTag) {
    if (route.path == "/SurveyPreview") {
      metaTag.content = 'width=device-width, initial-scale=1, maximum-scale=5, minimum-scale=1';
    } else {
      metaTag.content = 'width=device-width, initial-scale=1, maximum-scale=5, minimum-scale=1';
    }
  }

  return layout
})

</script>

<template>

  <layout-contents-main v-if="layout === 'layoutContentsMain'"></layout-contents-main>

  <layout-contents v-if="layout === 'layoutContents'"></layout-contents>

  <layout-full v-if="layout === 'layoutFull'"></layout-full>

</template>

<style>
</style>
